import NextLink from 'next/link';

import styles from '../../styles/AboutUs.module.scss';
import { useMobile } from '../../utils/useMobile';

const AboutUs = () => {
  const { isMobile } = useMobile();
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <p className={styles.header}>O nas</p>
          <p className={styles.text}>
            Największa lokalna telewizja informacyjna w centralnej Polsce
            i&nbsp;jedyny w regionie kanał nadający w rozdzielczości HD.
            Wydarzenia, publicystyka, rozrywka, sport i&nbsp;reklama. Nadajemy
            od 1997 roku.
          </p>
          <NextLink href={'/onas'}>
            <p className={styles.linkShowMore}>
              Zobacz więcej
              <img src={isMobile ? '/darkBlueArrow.svg' : '/whiteArrow.svg'} />
            </p>
          </NextLink>
        </div>
        <div className={styles.imagesContainer}>
          <div className={styles.imagesWrapper}>
            <img src='/ela_grzeszczuk.png' className={styles.personFirst} />
            <img src='/barbara_mrozinska.png' className={styles.personSecond} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
